@font-face {
  font-family: Simplon Mono;
  src: url("SimplonMono-Light-WebS.6f2020ba.woff") format("woff"), url("SimplonMono-Light-WebS.58dff845.woff2") format("woff2");
}

body {
  font-family: Simplon Mono, monospace;
}

/*# sourceMappingURL=index.dc29a522.css.map */
